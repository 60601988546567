@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~@flaticon/flaticon-uicons/css/all/all";

:root {
    --primary-black: #07080b;
    --secondary-black: #212121;
    --black-hover: #3d3d3d;
    --primary-white: #c2c2c2;
    --secondary-white: #aaaaaa;
}

body {
    background-color: var(--primary-black);
    color: var(--primary-white);
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    /* height: 100vh; */
    /* width: 100vw; */
    display: flex;
    flex-direction: column;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #1f1f1f;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #383838;
    border-radius: 10px;
    border-color: #383838;
}

::-webkit-scrollbar-thumb:hover {
    background: #3d3d3d;
}
